<template>
<!--  <div v-if="!this.isWheelPage">
    <div class="splash"><img style="width:353px;height:241px;" src="/images/app_screen.png" alt="app screenshot"></div>
    <div class="title">{{ $t('appShow.Title') }}</div>
    <div class="desc">{{ $t('appShow.Desc') }}</div>
    <div class="app-goto">
      <a onclick="gotoAppMarket('android')" target="_blank"
        href="https://play.google.com/store/apps/details?id=com.randompicker.wheeldecides.randomnamepicker.spinthewheel">
        <img style="width:220px;height:68px;" alt="Get it on Google play" src="/images/get-on-gp.png">
      </a>
      <br/>
      <a onclick="gotoAppMarket('ios')" target="_blank"
        href="https://apps.apple.com/app/spinning-wheel-roulette/id1661411013">
        <img style="width:220px;height:68px;" alt="Get it on AppStore" src="/images/get-on-appstore.png">
      </a>
    </div>
    <div>
      <h2 style="font-weight: bold;text-align: center"><span>{{ $t('appShow.Downloads') }}</span>&nbsp;&nbsp;2M+</h2>
      <h2 style="font-weight: bold;text-align: center"><span>{{ $t('appShow.MAU') }}</span>&nbsp;&nbsp;350K+</h2>
    </div>
  </div>
  <div v-else>

  </div>-->
</template>

<script>

import * as Util from "./Util";

export default {
  name: "appDesc",
  props: {
    isWheelPage: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isWheelPage: this.isWheelPage
    }
  },
  methods: {
    gotoAppMarket(app) {
      Util.gotoInGoogleAnalytics('gotoAppMarket', app, '')
    }
  }
}
</script>

<style scoped>
.splash {
  width: auto;
  height: 260px;
  display: inline-block;
  vertical-align: middle;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title {
  font-size: 28px;
  font-weight: bold;
  font-family: Helvetica, serif;
}

.app-goto {
  padding-top: 20px;
  padding-left: 40px;
  width: 260px;
  display: inline-block;
  vertical-align: middle;
}
</style>